import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import inLocale from 'date-fns/locale/en-IN';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { styles } from '../common/styles.js';

const OfficialDetails = ({
  state,
  handleChange,
  handlePrev,
  handleDateChange,
  handleSubmit,
}) => {
  const checked = [
    'I agree that the personal data, which has been provided to IAESTE, may be passed to IAESTE member countries (full members, associate members and cooperating institutions) and potential employers for the purpose of arranging my traineeship. Also my personal data may be provided to government authorities for the issuance of visa/work permit purpose.',
    'I am aware that I am not allowed to contact the company of the Receiving Country before being accepted.',
    'I understand that I am accepted for this training offer only after receipt of the IAESTE acceptance form.',
    'I confirm that all the data I provide is correct.',
    'I confirm to assume all of the foregoing risks, and accept personal responsibility for any injury to my myself (including, but not limited to, personal injury, disability, and death), illness, damage, loss, claim, liability or expense, of any kind or nature, that I may suffer arising out of or in connection with myself becoming exposed to or infected by COVID-19 while I am doing an IAESTE internship.',
  ];

  const [checkedState, setCheckedState] = useState(
    new Array(checked.length).fill(false)
  );

  const handleOnCheckBoxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCheckSubmit = (e) => {
    if (!checkedState.every(Boolean)) {
      setOpen(true);
      return;
    }

    e.preventDefault();
    handleSubmit(e);
  };

  return (
    <Paper style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Disclaimer
        </Typography>
      </Box>

      <List>
        {checked.map((text, index) => {
          const labelId = `checkbox-list-label-${index}`;
          return (
            <ListItem key={index}>
              <ListItemIcon>
                <Checkbox
                  checked={checkedState[index]}
                  onChange={() => handleOnCheckBoxChange(index)}
                  inputProps={{ 'aria-labelledby': labelId }}
                  error="true"
                />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>

      <Grid container spacing={1} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="date"
              label="Today's Date"
              value={state.dateObjects.date}
              onChange={handleDateChange('date')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Grid item style={{ marginRight: '20px' }}>
          <Button
            variant="contained"
            onClick={handlePrev}
            color="secondary"
            startIcon={<NavigateBefore />}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleCheckSubmit}
            color="secondary"
          >
            Finish
          </Button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
              variant="filled"
            >
              Kindly agree to all the given disclaimers.
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OfficialDetails;
