import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';

import inLocale from 'date-fns/locale/en-IN';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { styles } from '../common/styles.js';

const OfferRelatedDetails = ({
  state,
  handleChange,
  handleDateChange,
  handleNext,
  handlePrev,
}) => {
  return (
    <Paper style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in your Offer Related Details
        </Typography>
      </Box>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referenceNo"
            label="Offer Reference No"
            onChange={handleChange('referenceNo')}
            defaultValue={state.data.referenceNo}
            variant="outlined"
            size="small"
            fullWidth
            required
            error={state.errors.referenceNo}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.label}>
        <Grid item xs={12} sm={6}>
          Preferred period of training:
        </Grid>
      </Grid>

      <Grid container spacing={3} style={styles.grid}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="startIssue"
              label="Start Date"
              value={state.dateObjects.startDate}
              onChange={handleDateChange('startDate')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="endDate"
              label="End Date"
              value={state.dateObjects.endDate}
              onChange={handleDateChange('endDate')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.label}>
        <Grid item xs={12} sm={6}>
          Maximum period of availability for the training:
        </Grid>
      </Grid>

      <Grid container spacing={3} style={styles.grid}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="maxStartDate"
              label="Start Date"
              value={state.dateObjects.maxStartDate}
              onChange={handleDateChange('maxStartDate')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="maxEndDate"
              label="End Date"
              value={state.dateObjects.maxEndDate}
              onChange={handleDateChange('maxEndDate')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12}>
          <FormLabel style={styles.formLabel} component="legend">
            Do you wish lodging to be found for you?
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={state.data.lodging}
            exclusive
            onChange={handleChange('lodging')}
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12}>
          <FormLabel style={styles.formLabel} component="legend">
            Are you required/do you wish to prepare a technical report during
            the training period?
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={state.data.report}
            exclusive
            onChange={handleChange('report')}
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Grid item style={{ marginRight: '20px' }}>
          <Button
            variant="contained"
            onClick={handlePrev}
            color="secondary"
            startIcon={<NavigateBefore />}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleNext}
            color="secondary"
            endIcon={<NavigateNext />}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OfferRelatedDetails;
