import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    // type: 'light',
    // primary: {
    //   main: '#0d0d0d',
    // },
    secondary: {
      main: '#ff8e3c',
    },
    background: {
      default: '#fffffe',
      paper: '#fffffe',
    },
    text: {
      primary: '#3f3f3f',
      secondary: '#555555',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
