import React from 'react';

import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { styles } from '../common/styles';

const Header = ({ state }) => {
  return (
    <Paper style={styles.mainPaper}>
      <img
        style={styles.logo}
        src="/Assets/IAESTE India Vertical.svg"
        alt="IAESTE Logo"
      />
      <div style={{ width: '100%', overflow: 'auto' }}>
        <Stepper activeStep={state.stepCount} alternativeLabel>
          {state.steps.map((item) => (
            <Step key={item.label}>
              <StepLabel>{item.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </Paper>
  );
};

export default Header;
