import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import NavigateNext from '@mui/icons-material/NavigateNext';

import inLocale from 'date-fns/locale/en-IN';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { styles } from '../common/styles.js';

const PersonalDetails = ({
  state,
  handleChange,
  handleDateChange,
  handleNext,
}) => {
  return (
    <Paper style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in your Personal Details
        </Typography>
      </Box>

      <Grid container spacing={3} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="surname"
            label="Surname"
            onChange={handleChange('surname')}
            defaultValue={state.data.surname}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
            required
            error={state.errors.surname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="givenName"
            label="Given Name(s)"
            onChange={handleChange('givenName')}
            defaultValue={state.data.givenName}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
            required
            error={state.errors.givenName}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="dateOfBirth"
              label="Date Of Birth"
              value={state.dateObjects.dob}
              onChange={handleDateChange('dob')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12} sm={3}>
          <FormLabel style={styles.formLabel} component="legend">
            Gender
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={state.data.gender}
            exclusive
            onChange={handleChange('gender')}
          >
            <ToggleButton value="Male">Male</ToggleButton>
            <ToggleButton value="Female">Female</ToggleButton>
            <ToggleButton value="Others">Others</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12}>
          <FormLabel style={styles.formLabel} component="legend">
            Do you have medical conditions that could prevent you from
            undertaking a traineeship?
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={state.data.medicalStatus}
            exclusive
            onChange={handleChange('medicalStatus')}
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      {state.data.medicalStatus === 'Yes' && (
        <Grid container spacing={1} style={styles.grid}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="medicalStatus"
              label="Give a brief about your medical condition."
              onChange={handleChange('medicalCondition')}
              defaultValue={state.data.medicalCondition}
              variant="outlined"
              size="small"
              helperText="Should not exceed 100 characters"
              fullWidth
              required
              error={state.errors.medicalCondition}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            onClick={handleNext}
            color="secondary"
            endIcon={<NavigateNext />}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PersonalDetails;
