export const styles = {
  mainContainer: {
    display: 'flex',
    flexFlow: 'row Wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    width: '75%',
    paddingBottom: 10,
    paddingTop: 10,
    '@media (max-width: 768px)': {
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  mainPaper: {
    marginBottom: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  logo: {
    width: 80,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginBottom: 30,
  },
  steps: {
    padding: 50,
  },
  heading: {
    marginBottom: 30,
  },
  label: {
    marginTop: 20,
    paddingBottom: 20,
  },
  formLabel: {
    paddingBottom: 10,
  },
  grid: {
    marginBottom: 30,
  },
};
