import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';

import { styles } from '../common/styles.js';

const EducationalDetails = ({
  state,
  handleChange,
  handleNext,
  handlePrev,
}) => {
  return (
    <Paper style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in your Educational Details
        </Typography>
      </Box>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="university"
            label="University / College"
            onChange={handleChange('university')}
            defaultValue={state.data.university}
            variant="outlined"
            size="small"
            helperText="Enter the name of the university that you are enrolled in."
            fullWidth
            required
            error={state.errors.university}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="faculty"
            label="Faculty"
            onChange={handleChange('faculty')}
            defaultValue={state.data.faculty}
            variant="outlined"
            size="small"
            helperText="Enter your branch name"
            fullWidth
            required
            error={state.errors.faculty}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="specialization"
            label="Specialization"
            onChange={handleChange('specialization')}
            defaultValue={state.data.specialization}
            variant="outlined"
            size="small"
            helperText="If not applicable, enter your branch name."
            fullWidth
            required
            error={state.errors.specialization}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="completedYears"
            label="Completed Years Of Study"
            onChange={handleChange('completedYears')}
            defaultValue={state.data.completedYears}
            variant="outlined"
            size="small"
            fullWidth
            required
            error={state.errors.completedYears}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="totalYears"
            label="Total Years Of Study"
            onChange={handleChange('totalYears')}
            defaultValue={state.data.totalYears}
            variant="outlined"
            size="small"
            fullWidth
            required
            error={state.errors.totalYears}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="languages"
            label="Knowledge of languages"
            onChange={handleChange('languages')}
            defaultValue={state.data.languages}
            variant="outlined"
            size="small"
            required
            error={state.errors.languages}
            helperText={
              <>
                Format: German(2), French(1).
                <br />
                <b>Note: 1 = Excellent, 2 = Good, 3 = Fair</b>
              </>
            }
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Grid item style={{ marginRight: '20px' }}>
          <Button
            variant="contained"
            onClick={handlePrev}
            color="secondary"
            startIcon={<NavigateBefore />}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleNext}
            color="secondary"
            endIcon={<NavigateNext />}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EducationalDetails;
