import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import inLocale from 'date-fns/locale/en-IN';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { styles } from '../common/styles.js';

const PassportDetails = ({
  state,
  handleChange,
  handleDateChange,
  handleNext,
  handlePrev,
}) => {
  return (
    <Paper style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in your Passport Details
        </Typography>
      </Box>

      <Grid container spacing={1} style={styles.label}>
        <Grid item xs={12} sm={6}>
          Nationality:
        </Grid>
      </Grid>

      <Grid container spacing={3} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="nationality1"
            label="Nationality"
            onChange={handleChange('nationality1')}
            defaultValue={state.data.nationality1}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
            error={state.errors.nationality1}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="passportNo1"
            label="Passport Number"
            onChange={handleChange('passportNo1')}
            defaultValue={state.data.passportNo1}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={styles.grid}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="passportExpiry1"
              label="Date of Passport Expiry"
              value={state.dateObjects.passportExpiryDate1}
              onChange={handleDateChange('passportExpiryDate1')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12}>
          <FormLabel style={styles.formLabel} component="legend">
            Do you have dual citizenship?
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={state.data.dualCitizenStatus}
            exclusive
            onChange={handleChange('dualCitizenStatus')}
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      {state.data.dualCitizenStatus === 'Yes' && (
        <span>
          <Grid container spacing={1} style={styles.label}>
            <Grid item xs={12} sm={6}>
              Nationality 2: [Only for dual citizens]
            </Grid>
          </Grid>

          <Grid container spacing={3} style={styles.grid}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="nationality2"
                label="Nationality"
                onChange={handleChange('nationality2')}
                defaultValue={state.data.nationality2}
                variant="outlined"
                size="small"
                helperText="Details provided here should match official records"
                fullWidth
                error={state.errors.nationality2}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="passportNo2"
                label="Passport Number"
                onChange={handleChange('passportNo2')}
                defaultValue={state.data.passportNo2}
                variant="outlined"
                size="small"
                helperText="Details provided here should match official records"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} style={styles.grid}>
            <Grid item>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={inLocale}
              >
                <DesktopDatePicker
                  name="passportExpiry2"
                  label="Date of Passport Expiry"
                  value={state.dateObjects.passportExpiryDate2}
                  onChange={handleDateChange('passportExpiryDate2')}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </span>
      )}

      <Grid
        container
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Grid item style={{ marginRight: '20px' }}>
          <Button
            variant="contained"
            onClick={handlePrev}
            color="secondary"
            startIcon={<NavigateBefore />}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleNext}
            color="secondary"
            endIcon={<NavigateNext />}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PassportDetails;
