import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

import Header from './header/header';
import PersonalDetails from './Pages/PersonalDetails';
import PassportDetails from './Pages/PassportDetails';
import ContactInformation from './Pages/ContactInformation';
import EducationalDetails from './Pages/EducationalDetails';
import OfferRelatedDetails from './Pages/OfferRelatedDetails';
import OfficialDetails from './Pages/OfficialDetails';
import GeneratePdf from './snf';

import theme from './common/theme.js';
import { styles } from './common/styles.js';

const getDateFromObject = (dateObject) => {
  var dd = String(dateObject.getDate()).padStart(2, '0');
  var mm = String(dateObject.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = dateObject.getFullYear();

  return dd + '/' + mm + '/' + yyyy;
};

class FormComponent extends Component {
  constructor(props) {
    super(props);

    const todayDate = getDateFromObject(new Date());

    this.state = {
      data: {
        givenName: '',
        surname: '',
        gender: 'Male',
        dob: todayDate,
        medicalStatus: 'No',
        medicalCondition: '',

        nationality1: '',
        nationality2: '',
        passportNo1: '',
        passportNo2: '',
        passportExpiryDate1: todayDate,
        passportExpiryDate2: todayDate,
        dualCitizenStatus: 'No',

        homeAddress1: '',
        homeAddress2: '',
        homeAddress3: '',
        termAddress1: '',
        termAddress2: '',
        termAddress3: '',
        similarAddress: 'Yes',
        phone: '',
        alternativePhone: '',
        email: '',

        university: '',
        faculty: '',
        specialization: '',
        totalYears: '',
        completedYears: '',
        languages: '',

        referenceNo: '',
        endDate: todayDate,
        startDate: todayDate,
        maxStartDate: todayDate,
        maxEndDate: todayDate,
        lodging: 'Yes',
        report: 'Yes',

        date: todayDate,
      },
      dateObjects: {},
      steps: [
        { label: 'Personal Details' },
        { label: 'Passport Details' },
        { label: 'Contact Details' },
        { label: 'Educational Details' },
        { label: 'Offer Related Details' },
        { label: 'Disclaimer' },
      ],
      fieldName: [
        'personal',
        'passport',
        'contact',
        'educational',
        'offer',
        'official',
      ],
      fieldStatus: {
        personal: ['surname', 'givenName'],
        passport: ['nationality1'],
        contact: [
          'homeAddress1',
          'homeAddress2',
          'homeAddress3',
          'phone',
          'alternativePhone',
          'email',
          'alternativeEmail',
        ],
        educational: [
          'university',
          'faculty',
          'specialization',
          'totalYears',
          'completedYears',
          'languages',
        ],
        offer: ['referenceNo'],
      },
      errors: {},
      stepCount: 0,
      snackbarStatus: false,
    };
  }

  render() {
    const { classes } = this.props;

    const textSplitter = (text, limit) => {
      let result = ['', ''];
      let flag = false;
      text.split(' ').forEach((value) => {
        if (!flag && result[0].length + value.length <= limit) {
          result[0] += value + ' ';
        } else {
          result[1] += value + ' ';
          flag = true;
        }
      });
      return result;
    };

    const handleSubmit = (e) => {
      let dataRef = { ...this.state.data };

      // Home Address same as termAddress Condition
      if (dataRef.similarAddress === 'Yes') {
        dataRef.termAddress1 = dataRef.homeAddress1;
        dataRef.termAddress2 = dataRef.homeAddress2;
        dataRef.termAddress3 = dataRef.homeAddress3;
      }

      // Replace space with N/A
      Object.keys(dataRef).forEach(function (key) {
        if (key !== 'email') {
          dataRef[key] = dataRef[key].toUpperCase() || 'N/A';
        } else {
          dataRef[key] = dataRef[key] || 'N/A';
        }
      });

      // Line splitting
      dataRef.university = textSplitter(dataRef.university, 42);
      dataRef.faculty = textSplitter(dataRef.faculty, 50);
      dataRef.specialization = textSplitter(dataRef.specialization, 46);

      GeneratePdf(dataRef);
    };

    const handleChange = (input) => (e, value) => {
      const newValue =
        value === undefined || value === null ? e.target.value : value;
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [input]: newValue,
        },
      }));
    };

    const handleDateChange = (input) => (dateObject) => {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [input]:
            dateObject !== null && dateObject.toString() !== 'Invalid Date'
              ? getDateFromObject(dateObject)
              : dateObject,
        },
        dateObjects: {
          ...prevState.dateObjects,
          [input]: dateObject,
        },
      }));
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ snackbarStatus: false });
    };

    const checkFilled = () => {
      const state = this.state;
      const data = state.data;
      const stepNo = state.stepCount;
      const fieldName = state.fieldName[stepNo];
      const errors = {};
      if (fieldName) {
        const fields = state.fieldStatus[fieldName];
        const fieldLength = fields.length;
        for (var num = 0; num < fieldLength; num++) {
          if (data[fields[num]] === '') {
            errors[fields[num]] = true;
          }
        }
        // Exception 1
        if (
          data['medicalStatus'] === 'Yes' &&
          data['medicalCondition'] === ''
        ) {
          errors['medicalCondition'] = true;
        }

        // Exception 2
        if (
          data['dualCitizenStatus'] === 'Yes' &&
          data['nationality2'] === ''
        ) {
          errors['nationality2'] = true;
        }

        // Exception 3
        if (data['similarAddress'] === 'No') {
          if (data['termAddress1'] === '') {
            errors['termAddress1'] = true;
          }
          if (data['termAddress2'] === '') {
            errors['termAddress2'] = true;
          }
          if (data['termAddress3'] === '') {
            errors['termAddress3'] = true;
          }
        }
      }
      this.setState({ errors: errors });
      if (Object.keys(errors).length === 0 && errors.constructor === Object) {
        return true;
      }
      return false;
    };

    const handleNext = () => {
      if (!checkFilled()) {
        this.setState({ snackbarStatus: true });
        return;
      }
      let { stepCount } = this.state;
      stepCount = stepCount + 1;
      this.setState({ stepCount });
    };

    const handlePrev = () => {
      let { stepCount } = this.state;
      stepCount = stepCount - 1;
      this.setState({ stepCount });
    };

    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return (
            <PersonalDetails
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handleDateChange={handleDateChange}
            />
          );
        case 1:
          return (
            <PassportDetails
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleDateChange={handleDateChange}
            />
          );
        case 2:
          return (
            <ContactInformation
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          );
        case 3:
          return (
            <EducationalDetails
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          );
        case 4:
          return (
            <OfferRelatedDetails
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleDateChange={handleDateChange}
            />
          );
        case 5:
          return (
            <OfficialDetails
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleSubmit={handleSubmit}
              handleDateChange={handleDateChange}
            />
          );
        default:
          return <h1>Error</h1>;
      }
    };

    return (
      <ThemeProvider theme={theme}>
        <Grid container className={classes.mainContainer}>
          <Grid item className={classes.formContainer}>
            {this.state.stepCount <= 5 && <Header state={this.state} />}

            {getStepContent(this.state.stepCount)}
            <Snackbar
              open={this.state.snackbarStatus}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: '100%' }}
                variant="filled"
              >
                Fill all the required entries.
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

FormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormComponent);
