import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';

import { styles } from '../common/styles.js';

const PassportDetails = ({ state, handleChange, handleNext, handlePrev }) => {
  return (
    <Paper style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in your Contact Details
        </Typography>
      </Box>

      <Grid container spacing={1} style={styles.label}>
        <Grid item xs={12} sm={6}>
          Home Address:
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <FormLabel style={styles.formLabel} component="legend">
            Line 1
          </FormLabel>
          <TextField
            name="homeAddress1"
            label="House no, Street Name, Locality Name"
            onChange={handleChange('homeAddress1')}
            defaultValue={state.data.homeAddress1}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
            required
            error={state.errors.homeAddress1}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <FormLabel style={styles.formLabel} component="legend">
            Line 2
          </FormLabel>
          <TextField
            name="homeAddress2"
            label="District Name, City Name"
            onChange={handleChange('homeAddress2')}
            defaultValue={state.data.homeAddress2}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
            required
            error={state.errors.homeAddress2}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <FormLabel style={styles.formLabel} component="legend">
            Line 3
          </FormLabel>
          <TextField
            name="homeAddress3"
            label="Pin, State Name, Country Name"
            onChange={handleChange('homeAddress3')}
            defaultValue={state.data.homeAddress3}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
            required
            error={state.errors.homeAddress3}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid}>
        <Grid item xs={12}>
          <FormLabel style={styles.formLabel} component="legend">
            Is the address during term the same as home address?
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={state.data.similarAddress}
            exclusive
            onChange={handleChange('similarAddress')}
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      {state.data.similarAddress === 'No' && (
        <span>
          <Grid container spacing={1} style={styles.label}>
            <Grid item xs={12} sm={6}>
              Address during Terms:
            </Grid>
          </Grid>

          <Grid container spacing={1} style={styles.grid}>
            <Grid item xs={12} sm={6}>
              <FormLabel style={styles.formLabel} component="legend">
                Line 1
              </FormLabel>
              <TextField
                name="termAddress1"
                label="House no, Street Name, Locality Name"
                onChange={handleChange('termAddress1')}
                defaultValue={state.data.termAddress1}
                variant="outlined"
                size="small"
                helperText="Details provided here should match official records"
                fullWidth
                required
                error={state.errors.termAddress1}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} style={styles.grid}>
            <Grid item xs={12} sm={6}>
              <FormLabel style={styles.formLabel} component="legend">
                Line 2
              </FormLabel>
              <TextField
                name="termAddress2"
                label="District Name, City Name"
                onChange={handleChange('termAddress2')}
                defaultValue={state.data.termAddress2}
                variant="outlined"
                size="small"
                helperText="Details provided here should match official records"
                fullWidth
                required
                error={state.errors.termAddress2}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} style={styles.grid}>
            <Grid item xs={12} sm={6}>
              <FormLabel style={styles.formLabel} component="legend">
                Line 3
              </FormLabel>
              <TextField
                name="termAddress3"
                label="Pin, State Name, Country Name"
                onChange={handleChange('termAddress3')}
                defaultValue={state.data.termAddress3}
                variant="outlined"
                size="small"
                helperText="Details provided here should match official records"
                fullWidth
                required
                error={state.errors.termAddress3}
              />
            </Grid>
          </Grid>
        </span>
      )}

      <Grid container spacing={1} style={styles.label}>
        <Grid item xs={12} sm={6}>
          Phone Number
        </Grid>
      </Grid>

      <Grid container spacing={3} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="phone"
            label="Phone Number"
            onChange={handleChange('phone')}
            defaultValue={state.data.phone}
            variant="outlined"
            size="small"
            helperText="Format: +91 xxxxxxxxxx "
            fullWidth
            required
            error={state.errors.phone}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="alternativePhone"
            label="Alternative Phone Number"
            onChange={handleChange('alternativePhone')}
            defaultValue={state.data.alternativePhone}
            variant="outlined"
            size="small"
            helperText="Format: +91 xxxxxxxxxx "
            fullWidth
            required
            error={state.errors.alternativePhone}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.label}>
        <Grid item xs={12} sm={6}>
          Email Address
        </Grid>
      </Grid>

      <Grid container spacing={3} style={styles.grid}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="email"
            label="Email Address"
            onChange={handleChange('email')}
            defaultValue={state.data.email}
            variant="outlined"
            size="small"
            helperText="Provide a valid email id"
            fullWidth
            required
            error={state.errors.email}
          />
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Grid item style={{ marginRight: '20px' }}>
          <Button
            variant="contained"
            onClick={handlePrev}
            color="secondary"
            startIcon={<NavigateBefore />}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleNext}
            color="secondary"
            endIcon={<NavigateNext />}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PassportDetails;
